import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import LeftMenu from "../Components/LeftMenu";

const Patterns = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>
            The patterns I use 99% of the time for texturing in Photoshop.
            <br />
            <br />
            To import them, open the patterns window in Photoshop and click import, locate the downloaded file.
          </p>
          <Image fluid src={"https://i.imgur.com/xALXBmG.png"} alt="" width="700" />
          <br />
          <br />
          <Button className="btnrightmargin" variant="dark" href="https://intense.ws/intense.pat">
            Intense Patterns
          </Button>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Patterns;
