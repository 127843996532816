import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import TopItems from "../Components/Topitems";

const Paginatest = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="toph1 font-mont">Top 9 C:S Assets this week</h1>
          <TopItems />
        </Col>
      </Row>
    </Container>
  );
};

export default Paginatest;
