import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftMenu from "../Components/LeftMenu";
import "./own.css";

const blenderstartup = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>WIP New video soon</p>
        </Col>
      </Row>
    </Container>
  );
};

export default blenderstartup;
