import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import LeftMenu from "../Components/LeftMenu";
import "./own.css";

const cimmodel = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>
            Those are two models based on the Cities Skylines CIMs, named "Gigi", useful for figuring out sizes/dimensions of benches, door entrances, etc.
            <br />
            <br />
            Just download the files and import them in your 3D software.
          </p>
          <Image fluid src={"https://i.imgur.com/JWhrXYB.png"} alt="" width="500" />
          <br />
          <br />
          <Button className="btnrightmargin" variant="dark" href="https://intense.ws/resourcefiles/Gigi.fbx">
            Gigi Standing
          </Button>
          <Button variant="dark" href="https://intense.ws/resourcefiles/sitting.fbx">
            Gigi Sitting
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default cimmodel;
