import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import LeftMenu from "../Components/LeftMenu";

const Normalmapviewport = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>Tutorial on how to see your normal map on the mesh directly in the Blender viewport</p>
          <iframe
            className="myvideo"
            src="https://www.youtube.com/embed/-0L5TNQ39S0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Normalmapviewport;
