import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import LeftMenu from "../Components/LeftMenu";

const normalmapactions = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>
            My normal map actions for manually creating normal maps.
            <br />
            <br />
            To import them, open the actions window in Photoshop and click import, locate the downloaded file.
          </p>
          <Image fluid src={"https://i.imgur.com/7usmLk6.png"} alt="" width="350" />
          <br />
          <br />
          <Button className="btnrightmargin" variant="dark" href="https://intense.ws/resourcefiles/Intense_normal.atn">
            Intense Normal Map Actions
          </Button>
          <br />
          <br />
          <p>Video on manually creating normal map with the actions:</p>
          <iframe
            className="myvideo"
            src="https://www.youtube.com/embed/BAz2UK-eAAk"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default normalmapactions;
