import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavbarComponent from "./Components/NavbarComponent";
import Assetoptimization from "./pages/Assetoptimization";
import AssetsChecker from "./pages/AssetsChecker";
import Blenderaddons from "./pages/Blenderaddons";
import Blenderstartup from "./pages/Blenderstartup";
import Cimmodel from "./pages/Cimmodel";
import Commissions from "./pages/Commissions";
import Fastfilerenamer from "./pages/Fastfilerenamer";
import Home from "./pages/Home";
import Intenseutilities from "./pages/Intenseutilities";
import Lodbaking from "./pages/Lodbaking";
import Normalmapactions from "./pages/Normalmapactions";
import Normalmapviewport from "./pages/Normalmapviewport";
import Paginatest from "./pages/Paginatest";
import Patterns from "./pages/Patterns";
import Resources from "./pages/Resources";
import Textureresolution from "./pages/Textureresolution";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavbarComponent />
        <Routes>
          <Route index element={<Home />} />
          <Route path="commissions" element={<Commissions />} />
          <Route path="assetschecker" element={<AssetsChecker />} />
          <Route path="resources" element={<Resources />} />
          <Route path="patterns" element={<Patterns />} />
          <Route path="lodbaking" element={<Lodbaking />} />
          <Route path="textureresolution" element={<Textureresolution />} />
          <Route path="cimmodel" element={<Cimmodel />} />
          <Route path="blenderaddons" element={<Blenderaddons />} />
          <Route path="blenderstartup" element={<Blenderstartup />} />
          <Route path="normalmapactions" element={<Normalmapactions />} />
          <Route path="intenseutilities" element={<Intenseutilities />} />
          <Route path="normalmapviewport" element={<Normalmapviewport />} />
          <Route path="fastfilerenamer" element={<Fastfilerenamer />} />
          <Route path="assetoptimization" element={<Assetoptimization />} />
          <Route path="paginatest" element={<Paginatest />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
