import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { ImArrowUpRight2 } from "react-icons/im";

function FrontComponent({ data }) {
  return (
    //condition ? true : false;
    <>
      {(data.images.length > 1 && (
        <Carousel>
          {data.images.map((renderimg, index) => {
            return (
              <Carousel.Item key={index}>
                <img className="d-block w-100" src={renderimg} alt={data.title} />
              </Carousel.Item>
            );
          })}
        </Carousel>
      )) || <img className="d-block w-100" src={data.images[0]} alt={data.title} />}
      <div className="assetdescription">
        <h2 className="assettitle">{data.title}</h2>
        <p>{data.description}</p>
        <p>{data.tris}</p>
        <p>{data.resolution}</p>
        {data.gmaps && (
          <p>
            <a className="outsideurl" href={data.gmaps}>
              Google Maps <ImArrowUpRight2 />
            </a>
          </p>
        )}
        {data.steam && (
          <p>
            <a className="outsideurl" href={data.steam}>
              Steam Link <ImArrowUpRight2 />
            </a>
          </p>
        )}
        {data.patreon && (
          <p>
            <a className="outsideurl" href={data.patreon}>
              Available on Patreon <ImArrowUpRight2 />
            </a>
          </p>
        )}
      </div>
    </>
  );
}

export default FrontComponent;
