import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ImArrowUpRight2 } from "react-icons/im";
import LeftMenu from "../Components/LeftMenu";
import "./own.css";

const Blenderaddons = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>
            Those are the addons that I use the most in Blender. All of them are free. Just go to respective pages and download them.
            <br />
            <br />
            On all the github pages just look on the right sidebar for the latest releases and download the zip file. If you have troubles, contact me
            <br />
            <br />
            <a className="outsideurl" href="https://github.com/samytichadou/Auto_Reload_Blender_addon" target="_blank" rel="noreferrer">
              Auto Reload
              <ImArrowUpRight2 />
            </a>
          </p>
          <p>
            <a className="outsideurl" href="https://github.com/SavMartin/TexTools-Blender" target="_blank" rel="noreferrer">
              TexTools
              <ImArrowUpRight2 />
            </a>
          </p>
          <p>
            <a className="outsideurl" href="https://github.com/mrven/Blender-Texel-Density-Checker" target="_blank" rel="noreferrer">
              Texel Density Checker
              <ImArrowUpRight2 />
            </a>
          </p>
          <p>
            <a className="outsideurl" href="https://github.com/mrtripie/Blender-Super-Batch-Export" target="_blank" rel="noreferrer">
              Super Batch Export
              <ImArrowUpRight2 />
            </a>
          </p>
          <p>
            <a className="outsideurl" href="https://github.com/bonjorno7/PowerSave" target="_blank" rel="noreferrer">
              PowerSave
              <ImArrowUpRight2 />
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Blenderaddons;
