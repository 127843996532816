import axios from "axios";
import { useState } from "react";
import { Alert, Button, Col, Container, Form, FormControl, Image, Pagination, Row, Table } from "react-bootstrap";
import ExampleImage from "./example.png";

const AssetsChecker = () => {
  const [steam64id, setSteam64id] = useState("");
  const [perPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [publisheditems, setPublisheditems] = useState([]);

  const iloveshrimps = () => {
    console.log("Btn Pressed");
    axios
      .get(`https://steamassets.molovo.workers.dev/?id=${steam64id}`)
      .then((response) => {
        setPublisheditems(response.data.response.publishedfiledetails);
        console.log(publisheditems);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [sort, setSort] = useState({ field: "", order: "asc" });

  const getValueByPath = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const handleSort = (field) => {
    if (sort.field === field) {
      setSort({ field, order: sort.order === "asc" ? "desc" : "asc" });
    } else {
      setSort({ field, order: "asc" });
    }

    const sortedItems = [...publisheditems].sort((a, b) => {
      const aValue = getValueByPath(a, field);
      const bValue = getValueByPath(b, field);

      if (aValue < bValue) {
        return sort.order === "asc" ? -1 : 1;
      } else if (aValue > bValue) {
        return sort.order === "asc" ? 1 : -1;
      }
      return 0;
    });

    setPublisheditems(sortedItems);
  };

  const rows = publisheditems.length;

  const totalPages = Math.ceil(rows / perPage);
  const itemsToShow = 5;
  const showEllipsis = totalPages > itemsToShow + 2;

  const startPage = currentPage <= Math.ceil(itemsToShow / 2) ? 1 : currentPage - Math.floor(itemsToShow / 2);
  const endPage = Math.min(startPage + itemsToShow - 1, totalPages);

  const paginationItems = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationItems.push(
      <Pagination.Item key={i} active={i === currentPage} onClick={() => setCurrentPage(i)}>
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Container id="assets-checker">
      <Row>
        <Col>
          <h1 className="toph1 font-mont">STEAM PROFILE - ASSETS CHECKER</h1>
          <Form onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="d-flex justify-content-left">
              <FormControl id="steam64id" value={steam64id} onChange={(e) => setSteam64id(e.target.value)} type="text" className="form-control my-4" placeholder="Input steam64id" />
            </Form.Group>
            <div>
              <img src={ExampleImage} alt="exampleimage" />
              <br />
              <br />
              <a className="btn btn-primary" href="https://steamid.xyz" role="button" target="_blank" rel="noreferrer">
                <i className="fa-solid fa-arrow-right-arrow-left"></i> Convert username to steam64id
              </a>
              <br />
              <br />
              <Button onClick={iloveshrimps} type="submit">
                <i className="fa-solid fa-magnifying-glass"></i>
                Get Assets
              </Button>
            </div>
            <br />
            <Alert variant="warning">
              After clicking <b>"Get Assets"</b> wait for the API to wake up (it will take a few seconds)
              <br />
              Make sure you run this from the <b>https://intense.ws</b> version (no www)
              <br />
              <i>
                <b>Data is publicly accessible by anyone through the Public Steam API. This app just renders the data nicely.</b>
              </i>
            </Alert>
            <p>
              <i>App done in Node.js Express - deployed on Cloudflare Workers, VueJs2, Bootstrap, Boostrap-Vue, Axios - Intense & Molovo</i>
            </p>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col>
          <p className="mt-2">
            <strong>Current Page: {currentPage}</strong>
          </p>

          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)}>First</Pagination.First>
            <Pagination.Prev onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}>Prev</Pagination.Prev>
            {showEllipsis && startPage > 1 && <Pagination.Ellipsis />}
            {paginationItems}
            {showEllipsis && endPage < totalPages && <Pagination.Ellipsis />}
            <Pagination.Next onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}>Next</Pagination.Next>
            <Pagination.Last onClick={() => setCurrentPage(totalPages)}>Last</Pagination.Last>
          </Pagination>

          <Table striped bordered hover responsive="sm">
            <thead>
              <tr>
                <th>Asset Name</th>
                <th>Image</th>
                <th onClick={() => handleSort("subscriptions")}>Subscriptions {sort.field === "subscriptions" ? (sort.order === "asc" ? "▼" : "▲") : ""}</th>
                <th onClick={() => handleSort("favorited")}>Favorites {sort.field === "favorited" ? (sort.order === "asc" ? "▼" : "▲") : ""}</th>
                <th onClick={() => handleSort("views")}>Views {sort.field === "views" ? (sort.order === "asc" ? "▼" : "▲") : ""}</th>
                <th onClick={() => handleSort("vote_data.votes_up")}>Upvotes {sort.field === "vote_data.votes_up" ? (sort.order === "asc" ? "▼" : "▲") : ""}</th>
                <th onClick={() => handleSort("vote_data.votes_down")}>Downvotes {sort.field === "vote_data.votes_down" ? (sort.order === "asc" ? "▼" : "▲") : ""}</th>
                <th>URL</th>
              </tr>
            </thead>
            <tbody>
              {publisheditems.slice((currentPage - 1) * perPage, currentPage * perPage).map((item) => (
                <tr key={item.publishedfileid}>
                  <td>
                    <b>{item.title}</b>
                  </td>
                  <td>
                    <Image src={item.preview_url} fluid thumbnail width="150px" />
                  </td>
                  <td>{item.subscriptions}</td>
                  <td>{item.favorited}</td>
                  <td>{item.views}</td>
                  <td>{item.vote_data.votes_up}</td>
                  <td>{item.vote_data.votes_down}</td>
                  <td>
                    <Button variant="primary" href={`https://steamcommunity.com/sharedfiles/filedetails/?id=${item.publishedfileid}`} target="_blank">
                      Steam Link
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AssetsChecker;
