import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ASSETS from "../Components/Assets.js";
import FrontComponent from "../Components/FrontComponent.js";
import "./own.css";

function Home() {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="toph1 font-mont">3D WORK FOR CITIES SKYLINES</h1>
          {ASSETS.map((asset, index) => {
            return <FrontComponent key={index} data={asset} />;
          })}
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
