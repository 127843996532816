import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import LeftMenu from "../Components/LeftMenu";
import "./own.css";

const Fastfilerenamer = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>Simple way to rename multiple files (if you have for example multiple meshes which use the same texture)</p>
          <iframe
            className="myvideo"
            src="https://www.youtube.com/embed/229hS7P0JCc"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br />
          <br />
          <Button className="btnrightmargin" variant="dark" href="https://intense.ws/fastrename.bat">
            Intense Fastrename.bat
          </Button>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Fastfilerenamer;
