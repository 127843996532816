const ASSETS = [
  {
    images: ["https://i.imgur.com/HI7EBRW.jpg", "https://i.imgur.com/mFe3MYR.jpg", "https://i.imgur.com/lVTELlT.jpg", "https://i.imgur.com/X9ApUBg.jpg"],
    title: "Chanin Building New York",
    description: "Low Poly Asset",
    tris: "17694 Tris",
    resolution: "1024x2048",
    gmaps: "https://goo.gl/maps/NtQrZKdbjhE7qNrg7",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2846023520",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/EPeceKf.png"],
    title: "Mitchell House Melbourne",
    description: "Low Poly asset",
    tris: "3469 Tris",
    resolution: "1024x1024",
    gmaps: "https://goo.gl/maps/pBit37tUbhgEcRSB8",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2517514225",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/ty28kHs.png"],
    title: "Tromsø Domkirke",
    description: "Commissioned asset",
    tris: "10822 Tris",
    resolution: "1024x2048",
    gmaps: "https://goo.gl/maps/tKeu9KHmBsdFEp6Q7",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2517514225",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/kfkdbpG.png"],
    title: "Vienna Tattoo Shop",
    description: "Low Poly asset",
    tris: "2357 Tris",
    resolution: "1024x2048",
    gmaps: "https://goo.gl/maps/c6GZAXm7qmcrHnNZ9",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2569631797",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/4s5iPJa.png"],
    title: "Generic Office",
    description: "Low Poly asset",
    tris: "2001 Tris",
    resolution: "512x512",
    gmaps: "https://goo.gl/maps/qikmaKUv4DHKfc6g9",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2569631797",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/0O8777d.png"],
    title: "Car Wash",
    description: "Low Poly asset",
    tris: "1843 Tris",
    resolution: "512x512",
    gmaps: "https://goo.gl/maps/qikmaKUv4DHKfc6g9",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2569631797",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/JDTo4b6.png"],
    title: "Car Service Garages",
    description: "Low Poly asset",
    tris: "416 - 480 - 484 Tris",
    resolution: "512x1024",
    gmaps: "https://goo.gl/maps/qikmaKUv4DHKfc6g9",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2569631797",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/IqUlwgv.png"],
    title: "Ipiranga Gas Station",
    description: "Low Poly asset",
    tris: "3016 Tris",
    resolution: "512x1024",
    gmaps: "https://goo.gl/maps/qikmaKUv4DHKfc6g9",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2569631797",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/mkLhtHH.png"],
    title: "Lime e-Scooter",
    description: "Low Poly Asset",
    tris: "719 Tris",
    resolution: "512x256",
    gmaps: "https://goo.gl/maps/qikmaKUv4DHKfc6g9",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2456853464",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/ZwSiFp2.png"],
    title: "Edificio Elvira",
    description: "Commissioned asset",
    tris: "7308 Tris",
    resolution: "512x2048",
    gmaps: "https://goo.gl/maps/qikmaKUv4DHKfc6g9",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2654777804",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/MNEiREA.png"],
    title: "Silvermoon Lodge",
    description: "Commissioned asset",
    tris: "10969 Tris",
    resolution: "512x2048",
    gmaps: "https://goo.gl/maps/pAhm32xtFGxyYEtd7",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2437223362",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/Z09PW28.jpg"],
    title: "Timisoara Transelectrica Building",
    description: "Low Poly Asset",
    tris: "16790 Tris",
    resolution: "1024x1024",
    gmaps: "https://goo.gl/maps/DrtbwYotcpLGWa1H8",
    steam: "https://steamcommunity.com/sharedfiles/filedetails/?id=2484063597",
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/ziuHBVz.jpg", "https://i.imgur.com/sb9G03k.jpg"],
    title: "Pack of fences",
    description: "Low Poly Asset",
    tris: "Various Tris",
    resolution: "256x512",
    gmaps: "https://goo.gl/maps/a7uCb4rhFZrcx67u9",
    steam: null,
    patreon: "https://patreon.com/comradeintense",
  },
  {
    images: ["https://i.imgur.com/2VZosYm.jpg"],
    title: "European Tenement #1",
    description: "Low Poly Asset",
    tris: "2172 Tris",
    resolution: "512x1024",
    gmaps: "https://goo.gl/maps/a7uCb4rhFZrcx67u9",
    steam: null,
    patreon: "https://patreon.com/comradeintense",
  },
];

export default ASSETS;
