import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
// GetPlayeSummaries  https://steamapi.xpaw.me/#ISteamUser/GetPlayerSummaries
const TopItems = () => {
  const [topItems, setTopItems] = useState([]);
  const [itemDetails, setItemDetails] = useState({});

  useEffect(() => {
    async function fetchTopItems() {
      const response = await fetch("https://main--serverless-topitems.netlify.app/.netlify/functions/topitems");
      const data = await response.json();
      setTopItems(data);

      data.forEach(async (itemId) => {
        await fetchAndSetItemDetails(itemId);
      });
    }

    fetchTopItems();
  }, []);

  const fetchAndSetItemDetails = async (itemId) => {
    const proxyUrl = "https://intensews-server.onrender.com";
    const url = `${proxyUrl}/itemdetails/${itemId}`;

    const response = await fetch(url);
    const data = await response.json();
    const details = data.response.publishedfiledetails[0];
    setItemDetails((prevState) => ({ ...prevState, [itemId]: details }));
  };

  return (
    <div>
      <Container fluid>
        <Row>
          {topItems.map((itemId, index) => (
            <Col xs={4} key={index} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>Asset #{index + 1}</Card.Title>
                  {itemDetails[itemId] && (
                    <>
                      <Card.Img variant="top" src={itemDetails[itemId].preview_url} />
                      <Card.Title>{itemDetails[itemId].title}</Card.Title>
                      <Card.Subtitle className="mb-2 text-muted">Creator: {itemDetails[itemId].creator}</Card.Subtitle>
                      <Card.Text>File Size: {(itemDetails[itemId].file_size / 1048576).toFixed(3)} MB</Card.Text>
                      <Card.Text>ItemID: {itemDetails[itemId].publishedfileid}</Card.Text>
                      <Card.Text>Subscriptions: {itemDetails[itemId].subscriptions}</Card.Text>
                      <Card.Text>Favorited: {itemDetails[itemId].favorited}</Card.Text>
                      <Card.Text>Votes Up: {itemDetails[itemId].vote_data.votes_up}</Card.Text>
                      <Card.Text>Votes Down: {itemDetails[itemId].vote_data.votes_down}</Card.Text>
                    </>
                  )}
                </Card.Body>
              </Card>
              <br />
              <br />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default TopItems;
