import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ImArrowUpRight2 } from "react-icons/im";
import LeftMenu from "../Components/LeftMenu";

const Intenseutilities = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>
            Custom coded addon by me in python for Blender to speed up the workflow. Most commonly used tools/shortcuts into one single menu.
            <br />
            <br />
            Watch the video for explanation on how to use it.
          </p>
          <p>
            <a className="outsideurl" href="https://comradeintense.gumroad.com/l/intenseutilities" target="_blank" rel="noreferrer">
              Intense Utilities Addon
              <ImArrowUpRight2 />
            </a>
          </p>
          <iframe
            className="myvideo"
            src="https://www.youtube.com/embed/DySG2nh2ZLI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <p>
            <b>Update to v1.0.1 - SEE VIDEO FOR CHANGELOG</b>
          </p>
          <iframe
            className="myvideo"
            src="https://www.youtube.com/embed/C0QR1RcOu8k"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <br />
          <br />
        </Col>
      </Row>
    </Container>
  );
};

export default Intenseutilities;
