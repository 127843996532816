import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaDiscord, FaInstagram, FaPatreon, FaSteam, FaTwitter, FaYoutube } from "react-icons/fa";

const LINKS = [
  {
    icon: <FaDiscord />,
    url: "https://discord.gg/gx9PDAe",
  },
  {
    icon: <FaSteam />,
    url: "https://steamcommunity.com/id/comradeintense/myworkshopfiles/?appid=255710",
  },
  {
    icon: <FaPatreon />,
    url: "https://www.patreon.com/comradeintense",
  },
  {
    icon: <FaInstagram />,
    url: "https://instagram.com/comradeintense",
  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/comradeintense",
  },
  {
    icon: <FaYoutube />,
    url: "https://www.youtube.com/comradeintense",
  },
];

function NavbarComponent() {
  return (
    <Navbar expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/">Intense.ws</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">| portfolio</Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/commissions">Commissions</Nav.Link>
            <Nav.Link href="/resources">Resources</Nav.Link>
            <Nav.Link href="/assetschecker">Assets Checker</Nav.Link>
            {LINKS.map((item) => {
              return (
                <Nav.Link key={item.url} href={item.url}>
                  {item.icon}
                </Nav.Link>
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
