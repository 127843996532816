import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import LeftMenu from "../Components/LeftMenu";
import "./own.css";

const Textureresolution = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <Container>
            <h1 className="toph1 font-mont">ABOUT</h1>
            <p>
              How to decide on the texture resolution
              <br />
              <br />I wrote about this briefly at the end of my optimization guide: <b>the texture size must be made for the viewing distance that you desire</b>. The closer you get, the more resolution you need, and the further away less.
              <br />
              <br />
              Judging the distance must be done realistically and objectively by thinking about the distance where the players will look at your asset / take screenshots in-game. Nobody will zoom in extremely close to see that stain you added in a
              window.
              <br />
              <br />
              Example with an asset I did, a car wash. Realistically, most people will keep the camera at a distance where you can see most of the asset <b>(Image 1)</b>, and not zoomed in very close <b>(Image 2)</b>:
            </p>
            <Image fluid src={"https://i.imgur.com/elvVZLT.png"} alt="" />
            <br />
            <br />
            <Image fluid src={"https://i.imgur.com/stbysv1.png"} alt="" />
            <br />
            <br />
            <p>
              To illustrate this better, I ran some optimization tests in Unreal Engine 5, with the "Required Texture Resolution" tool to see based on the viewing distance from the camera, what resolution the GPU wants.
              <br />
              <br />
              Here are 3 cubes which are 8x8x8 meters, with a 1k, 2k, and 4k texture left to right. The camera is placed in the same spot/distance.{" "}
            </p>
            <Image fluid src={"https://i.imgur.com/2ltTIJq.jpeg"} alt="" />
            <br />
            <br />
            <p>
              The first cube is Good, the second one is 1X Over, and the third one is 2X Over more resolution than needed, based on the viewing distance and the size/pixels of my monitor.
              <br />
              <br />
              Based on the information above, here is an example with my own asset, the car wash. Ideally, you want to be in the orange-white area depending on the distance that you decide to make your texture for:
            </p>
            <br />
            <br />
            <Image fluid src={"https://i.imgur.com/jg6rzLt.png"} alt="" />
            <br />
            <br />
            <p>
              Take into consideration that I also have a 2k monitor, so the texture size is actually even better optimized for a 1080p monitor. For this asset, a 512x512 _diffuse texture was made.
              <br />
              <br />
              To sum it up: you decide the texture resolution based on the viewing distance for which your asset will be realistically seen. Don't go 2k-4k just to squeeze in as much detail as possible which will be unnecessary the further away you
              go with the camera, because it increases the file size tremendously.
            </p>
            <br />
            <br />
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Textureresolution;
