import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ImArrowUpRight2 } from "react-icons/im";
import LeftMenu from "../Components/LeftMenu";

const Patterns = () => {
  return (
    <Container>
      <Row>
        <Col lg="4">
          <LeftMenu />
        </Col>
        <Col lg="8">
          <h1 className="toph1 font-mont">ABOUT</h1>
          <p>Optimization guide for meshes & textures for Cities Skylines Assets. ~15 min reading time</p>
          <p>
            <a className="outsideurl" href="https://comradeintense.github.io/assetoptimization/" target="_blank" rel="noreferrer">
              Read Here (Hosted on github)
              <ImArrowUpRight2 />
            </a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Patterns;
