import React from "react";
import { NavLink } from "react-router-dom";

const LeftMenu = () => {
  return (
    <>
      <nav id="leftmenu">
        <h1 className="toph1 font-mont">RESOURCES FOR CREATORS</h1>
        <NavLink to="../assetoptimization">Asset Optimization Guide</NavLink>
        <br />
        <NavLink to="../textureresolution">
          Deciding Texture Resolution Size
        </NavLink>
        <br />
        <NavLink to="../cimmodel">CIM model for dimensions/sizes</NavLink>
        <br />
        <NavLink to="../blenderstartup">Blender Startup File</NavLink>
        <br />
        <NavLink to="../blenderaddons">Blender addons I recommend</NavLink>
        <br />
        <NavLink to="../intenseutilities">Intense Utilities Addon</NavLink>
        <br />
        <NavLink to="../patterns">My patterns for texturing</NavLink>
        <br />
        <NavLink to="../normalmapactions">My Normal Map Actions</NavLink>
        <br />
        <NavLink to="../lodbaking">Lod Baking Tutorial</NavLink>
        <br />
        <NavLink to="../normalmapviewport">Normal Map in Viewport</NavLink>
        <br />
        <NavLink to="../fastfilerenamer">Rename multiple files for CS Assets</NavLink>
        <br />
      </nav>
    </>
  );
};

export default LeftMenu;
