import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import TopItems from "../Components/Topitems";

const Commissions = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1 className="toph1 font-mont">WANT A CUSTOM BUILDING?</h1>
            <hr />
            <p>
              <b>Pricing</b>
              <br />
              <br />
              Rather than tracking time and charging by the hour, I have a range of prices based on the size and complexity of the building. Before we start, I like to have a quick chat with you to understand your needs and take a look at the
              building.
              <br />
              <br />
              Based on that, I can estimate how much work is involved in terms of modeling and texturing, and we can agree on a fair price in euros. I ask for full payment upfront, and I'll provide proof that work has begun. You can pay using PayPal,
              Revolut, Bitcoin, or Metamask.
              <br />
              <br />
              As a small guideline:
              <br />
              <hr />
              <li>
                A small house <b>30-50 EUR</b>
              </li>
              <li>
                A medium size commercial building/residential/industrial: <b>50-100 EUR</b>
              </li>
              <li>
                Big buildings: <b>100+ EUR</b>
              </li>
              <hr />
            </p>
            <p>
              One thing to note is that I'll be uploading the building to my Steam workshop, along with a short description and stats. However, if you prefer, I can make the building private for a certain period of time. Also, just so you know, I
              won't be sharing the Blender source file or PSD file with textures. Sound good to you? Let's chat!
            </p>
            <br />
            <br />
            <TopItems />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Commissions;
